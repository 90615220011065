import React, {useState} from 'react';
import classes from "./prizeDay.module.css";
import {text} from "../../translate";
import Confetti from 'react-confetti';
import {CSSTransition} from "react-transition-group";
import {server} from "../../../utils/api";

const PrizeDay = ({day, open, lang}) => {
    const [rotation, setRotation] = useState(0);
    const [showConfetti, setShowConfetti] = useState(false);
    const [step, setStep] = useState(0)
    const [prize, setPrize] = useState(
        {"item":
                {
                    "id": 22,
                    "name": "M30s++",
                    "img": "/media/uploads/items/img_wR7JrkN.png",
                    "firma": "Whatsminer",
                    "price": 1000,
                    "power": "3472",
                    "hashrate": "100 th"
                },
            "price": 900,
            "price_star": 50
        })

    const stylesDays = [
        {color: "#515569", gradient: 'linear-gradient(180deg, #979BAE 0%, #46495E 100%)'},
        {color: "#C34D51", gradient: 'linear-gradient(180deg, #E9B565 0%, #C24850 100%)'},
        {color: "#E96924", gradient: 'linear-gradient(180deg, #FF965E 0%, #E6621B 100%)'},
        {color: "#E59533", gradient: 'linear-gradient(180deg, #ECC55D 0%, #E48D2C 100%)'},
        {color: "#4AB235", gradient: 'linear-gradient(180deg, #85D164 0%, #40AD2C 100%)'},
        {color: "#3D31DE", gradient: 'linear-gradient(180deg, #8970E9 0%, #392EDE 100%)'},
        {color: "#2869D7", gradient: 'linear-gradient(180deg, #5E9FE1 0%, #1F60D6 100%)'}
    ]

    function rotate(){
        setRotation(prevRotation => prevRotation + 710+360);
        setTimeout(()=>{
            setShowConfetti(true);
            setTimeout(() => {
                setShowConfetti(false);
            }, 3200);
            setTimeout(() => {
                setStep(1)
            }, 1000);
        },2700)

    }

    return (
        <div className={classes._} style={{background:stylesDays[day].gradient}}>
            <div className={classes.close} onClick={()=>open(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M15 5L5 15M5 5L15 15" stroke="white" strokeOpacity="0.8" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <CSSTransition in={step===0}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <div className={classes.block}>
                    <div className={classes.text1} dangerouslySetInnerHTML={{__html:text[lang].prizeDay.text3}}>
                    </div>
                    <div className={classes.spinBlock} style={{background:stylesDays[day].color+'1E'}}>
                        <svg width="335" height="336" viewBox="0 0 335 336" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_3722_2687)">
                                <g style={{
                                    transform: `rotate(${rotation}deg)`,
                                    transformOrigin: 'center',
                                    transition: 'transform 3s ease-in-out',
                                }}>
                                    <path d="M321.832 102.806C313.397 82.8602 301.324 64.949 285.938 49.5627C270.562 34.1862 252.64 22.1128 232.705 13.6681C212.054 4.93758 190.114 0.5 167.5 0.5C144.891 0.5 122.952 4.93692 102.301 13.6681C82.355 22.1128 64.4385 34.1862 49.0568 49.5627C33.681 64.949 21.6023 82.8602 13.1675 102.806C4.43235 123.457 0 145.397 0 168C0 190.613 4.43169 212.553 13.1681 233.204C21.6023 253.15 33.6816 271.061 49.0574 286.448C64.4385 301.824 82.355 313.908 102.301 322.342C122.953 331.073 144.891 335.499 167.501 335.499C190.115 335.499 212.054 331.072 232.705 322.342C252.641 313.907 270.562 301.824 285.938 286.448C301.325 271.061 313.398 253.15 321.832 233.204C330.563 212.553 335 190.613 335 168C335 145.397 330.563 123.457 321.832 102.806Z" fill="white" fillOpacity="0.24"/>
                                    <path d="M65.9685 66.4745L65.9682 66.4749C38.8588 93.5911 23.915 129.664 23.915 168C23.915 206.345 38.8587 242.418 65.9682 269.533L65.9684 269.534C93.0837 296.651 129.151 311.588 167.496 311.588C205.84 311.588 241.913 296.651 269.018 269.534C296.134 242.418 311.082 206.345 311.082 168C311.082 129.664 296.134 93.5906 269.018 66.4752C241.913 39.3582 205.84 24.4199 167.496 24.4199C129.152 24.4199 93.0844 39.3587 65.9685 66.4745Z" fill="#444A53" stroke="white" strokeWidth="8"/>
                                    <path d="M114.057 297.029C130.773 303.961 148.858 307.589 167.5 307.589V168.01L114.057 297.029Z" fill="#ABD376"/>
                                    <path d="M220.949 38.9806C204.223 32.0494 186.147 28.4199 167.5 28.4199V168.01L220.949 38.9806Z" fill="#ABD376"/>
                                    <path d="M220.949 297.029C237.663 290.107 253.019 279.884 266.196 266.705L167.5 168.01L220.949 297.029Z" fill="#DC6068"/>
                                    <path d="M114.057 38.9805C97.3369 45.9018 81.9814 56.1249 68.7982 69.3035L167.5 168.01L114.057 38.9805Z" fill="#DC6068"/>
                                    <path d="M296.529 221.449C303.449 204.733 307.089 186.648 307.089 168.001H167.5L296.529 221.449Z" fill="#ABD376"/>
                                    <path d="M38.4706 114.562C31.5447 131.278 27.9152 149.363 27.9152 168H167.5L38.4706 114.562Z" fill="#ABD376"/>
                                    <path d="M296.53 114.562C289.608 97.8365 279.384 82.4915 266.196 69.3037L167.5 168L296.53 114.562Z" fill="#DC6068"/>
                                    <path d="M38.4706 221.449C45.3965 238.174 55.6202 253.529 68.7982 266.706L167.5 168L38.4706 221.449Z" fill="#DC6068"/>
                                </g>
                                <path d="M167.5 69.7744L188.696 123.54H167.5H146.304L167.5 69.7744Z" fill="#F7D069" stroke="white" strokeWidth="8"/>
                                <path d="M226 168.005C226 200.028 200.037 226 168.002 226C135.968 226 110 200.028 110 168.005C110 135.972 135.969 110 168.002 110C200.036 110 226 135.972 226 168.005Z" fill="white"/>
                                <path d="M168.001 118C140.425 118 118 140.435 118 168.001C118 195.59 140.425 218 168.001 218C195.59 218 218 195.59 218 168.001C218 140.437 195.59 118 168.001 118Z" fill="#F7D069"/>
                                <path d="M151 167.938C151 158.603 158.6 151 167.938 151C177.28 151 184.874 158.603 184.874 167.938C184.874 177.281 177.281 184.874 167.938 184.874C158.598 184.874 151 177.28 151 167.938Z" fill="white" stroke="#EC8B45" strokeWidth="8"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_3722_2687">
                                    <rect width="335" height="335" fill="white" transform="translate(0 0.5)"/>
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                    <div className={classes.buttonBlock}>
                        <div className={classes.button} style={{color:stylesDays[day].color}} onClick={rotate}>
                            {text[lang].prizeDay.text4}
                            <div className={classes.prizeCount} style={{background:stylesDays[day].color+'1E'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <path d="M5.14962 0.792288C5.48433 0.0666332 6.51567 0.0666331 6.85038 0.792288L7.91304 3.09614C8.04946 3.39189 8.32973 3.59552 8.65316 3.63387L11.1726 3.93259C11.9662 4.02668 12.2849 5.00754 11.6982 5.55011L9.83549 7.2727C9.59637 7.49383 9.48932 7.82331 9.55279 8.14276L10.0472 10.6312C10.203 11.415 9.36862 12.0212 8.6713 11.6309L6.45742 10.3917C6.17322 10.2326 5.82678 10.2326 5.54258 10.3917L3.3287 11.6309C2.63138 12.0212 1.79701 11.415 1.95275 10.6312L2.22287 9.27177C2.27623 9.00325 2.44442 8.77151 2.68319 8.63756L6.36433 6.57232C6.62487 6.42615 6.48628 6.02769 6.19127 6.07474L2.10134 6.72711C1.81766 6.77236 1.5289 6.68491 1.318 6.48987L0.301797 5.55012C-0.28491 5.00754 0.0337889 4.02668 0.827359 3.93259L3.34684 3.63387C3.67026 3.59552 3.95054 3.39189 4.08695 3.09614L5.14962 0.792288Z" fill={stylesDays[day].color}/>
                                </svg>
                                1
                            </div>
                        </div>
                        <div className={classes.text3}>{text[lang].prizeDay.text5}</div>
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={step===1}
                           timeout={300}
                           classNames="block"
                           //unmountOnExit
            >
                <div className={classes.block} style={{zIndex:step===0?-100:2, opacity:step===0?0:1}}>
                    <div>
                        <div className={classes.text2}>{text[lang].prizeDay.text6}</div>
                        <div className={classes.text1}>{text[lang].prizeDay.text7} {prize.item.price-prize.price}$ {text[lang].prizeDay.text8} <br/> {prize.item.firma} {prize.item.name} {prize.item.hashrate}</div>
                    </div>
                    <div className={classes.imageBlock}>
                        <img className={classes.image} src={server+prize.item.img} alt=''/>
                        <svg className={classes.image2} viewBox="0 0 200 200">
                            <circle cx="100" cy="100" r="0" fill="none" stroke="#FFF" strokeWidth="1">
                                <animate attributeName="r" from="0" to="100" dur="3s" repeatCount="indefinite" />
                                <animate attributeName="opacity" from="1" to="0" dur="3s" repeatCount="indefinite" />
                            </circle>
                            <circle cx="100" cy="100" r="0" fill="none" stroke="#FFF" strokeWidth="1">
                                <animate attributeName="r" from="0" to="100" dur="3s" begin="0.5s" repeatCount="indefinite" />
                                <animate attributeName="opacity" from="1" to="0" dur="3s" begin="0.5s" repeatCount="indefinite" />
                            </circle>
                            <circle cx="100" cy="100" r="0" fill="none" stroke="#FFF" strokeWidth="1">
                                <animate attributeName="r" from="0" to="100" dur="3s" begin="1s" repeatCount="indefinite" />
                                <animate attributeName="opacity" from="1" to="0" dur="3s" begin="1s" repeatCount="indefinite" />
                            </circle>
                            <circle cx="100" cy="100" r="0" fill="none" stroke="#FFF" strokeWidth="1">
                                <animate attributeName="r" from="0" to="100" dur="3s" begin="1.5s" repeatCount="indefinite" />
                                <animate attributeName="opacity" from="1" to="0" dur="3s" begin="1.5s" repeatCount="indefinite" />
                            </circle>
                            <circle cx="100" cy="100" r="0" fill="none" stroke="#FFF" strokeWidth="1">
                                <animate attributeName="r" from="0" to="100" dur="3s" begin="2s" repeatCount="indefinite" />
                                <animate attributeName="opacity" from="1" to="0" dur="3s" begin="2s" repeatCount="indefinite" />
                            </circle>
                            <circle cx="100" cy="100" r="0" fill="none" stroke="#FFF" strokeWidth="1">
                                <animate attributeName="r" from="0" to="100" dur="3s" begin="2.5s" repeatCount="indefinite" />
                                <animate attributeName="opacity" from="1" to="0" dur="3s" begin="2.5s" repeatCount="indefinite" />
                            </circle>
                        </svg>
                    </div>
                    <div className={classes.buttonBlock2}>
                        <div className={classes.button2} onClick={()=>{setStep(0)}}>
                            {text[lang].prizeDay.text9}
                            <div className={classes.prizeCount} style={{background:'rgba(255, 255, 255, 0.12)', gap:'5px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <path d="M5.14962 0.792288C5.48433 0.0666332 6.51567 0.0666331 6.85038 0.792288L7.91304 3.09614C8.04946 3.39189 8.32973 3.59552 8.65316 3.63387L11.1726 3.93259C11.9662 4.02668 12.2849 5.00754 11.6982 5.55011L9.83549 7.2727C9.59637 7.49383 9.48932 7.82331 9.55279 8.14276L10.0472 10.6312C10.203 11.415 9.36862 12.0212 8.6713 11.6309L6.45742 10.3917C6.17322 10.2326 5.82678 10.2326 5.54258 10.3917L3.3287 11.6309C2.63138 12.0212 1.79701 11.415 1.95275 10.6312L2.22287 9.27177C2.27623 9.00325 2.44442 8.77151 2.68319 8.63756L6.36433 6.57232C6.62487 6.42615 6.48628 6.02769 6.19127 6.07474L2.10134 6.72711C1.81766 6.77236 1.5289 6.68491 1.318 6.48987L0.301797 5.55012C-0.28491 5.00754 0.0337889 4.02668 0.827359 3.93259L3.34684 3.63387C3.67026 3.59552 3.95054 3.39189 4.08695 3.09614L5.14962 0.792288Z" fill="#FFF"/>
                                </svg>
                                1
                            </div>
                        </div>
                        <div className={classes.button3} style={{color:stylesDays[day].color}}>{text[lang].prizeDay.text10}</div>
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition in={showConfetti}
                           timeout={300}
                           classNames="block"
                           unmountOnExit
            >
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                />
            </CSSTransition>
        </div>
    );
};

export default PrizeDay;