export const server = 'https://api.imine.shop'
export const server_api = 'https://api.imine.shop/api/'


export async function get_info(){
    const res = await (await fetch(server_api+'get_info')).json();
    if(res.status==='OK'){
        return res.res
    }
    return false
}

export async function get_models(){
    const res = await (await fetch(server_api+'get_models')).json();
    if(res.status==='OK'){
        return res.res
    }
    return []
}

export async function get_items(id){
    const res = await (await fetch(server_api+'get_items?firma='+id)).json();
    if(res.status==='OK'){
        return res.res
    }
    return []
}

export async function get_item(id){
    const res = await (await fetch(server_api+'get_item?id='+id)).json();
    if(res.status==='OK'){
        return res.res
    }
    return []
}

export async function get_course(){
    try{
        const res = await (await fetch(server_api+'get_course')).json()
        return parseFloat(res.res.USD_RUB)
    }catch(e){
        return 100
    }
}

export async function get_courseBTC(){
    try{
        const res = await (await fetch(server_api+'get_course')).json()
        return parseFloat(res.res.BTC_RUB)
    }catch(e){
        return 100
    }
}

export async function get_stat(){
    try{
        const res = await (await fetch(server_api+'get_course')).json()
        return 86400*res.res.BTC/Math.pow(2,32)
    }catch(e){
        return 100
    }
}

export async function get_profit(){
    const res = await (await fetch(server_api+'get_profit')).json();
    if(res.status==='OK'){
        return res.res
    }
    return false
}

export async function new_client(name,tel,payment_method,address,comment,items,telegram,id_telegram,course){
    let itemsT = ''
    let price = 0
    items.forEach((item=>{
        itemsT += item.item.firma +' '+item.item.name+' '+item.item.hashrate+' '+item.count+'шт. по '+item.item.price+' USDT\n'
        price += item.item.price * item.count
    }))
    let pm = ['USDT TRC(20)', 'Безналичный расчет с НДС', 'На карту', 'Наличными', 'USDT (оплата с менеджером)']
    let user = {
        name:name,
        tel:tel,
        payment_method:pm[payment_method-1],
        address:address===''?'Самовывоз':address,
        comment:comment,
        items:itemsT,
        telegram:telegram? '@'+telegram : 'Нет Юзернейма',
        id_telegram:id_telegram,
        price:price
    };
    let response = await fetch(server_api+'new_client', {
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    });
    console.log(await response.json())
}


export async function invoice_create(name,tel,payment_method,address,comment,items,telegram,id_telegram,course){
    let itemsT = ''
    let price = 0
    items.forEach((item=>{
        itemsT += item.item.firma +' '+item.item.name+' '+item.item.hashrate+' '+item.count+'шт. по '+item.item.price+' USDT\n'
        price += item.item.price * item.count
    }))
    let pm = ['USDT TRC(20)', 'Безналичный расчет с НДС', 'На карту', 'Наличными', 'USDT (оплата с менеджером)']
    let user = {
        name:name,
        tel:tel,
        payment_method:pm[payment_method-1],
        address:address===''?'Самовывоз':address,
        comment:comment,
        items:itemsT,
        telegram:telegram? '@'+telegram : 'Нет Юзернейма',
        id_telegram:id_telegram,
        price:price
    };
    let response = await fetch(server_api+'invoice_create', {
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
                lead_data: user,
                invoice_data: {
                    amount: price
                }
            }
        )
    });
    return await response.json()
}


export async function tracking1(name,telegram,id_telegram){
    let user = {
        name:name,
        telegram:telegram ? '@'+telegram : 'Нет Юзернейма',
        id_telegram:id_telegram
    };
    let response = await fetch(server_api+'tracking1', {
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    });
    return await response.json()
}

export async function tracking2(id_telegram, items){
    let itemsT = ''
    items.forEach((item=>{
        itemsT += item.item.firma +' '+item.item.name+' '+item.item.hashrate+' '+item.count+'шт.\n'
    }))
    let user = {
        id_telegram:id_telegram,
        items:itemsT
    };
    let response = await fetch(server_api+'tracking2', {
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    });
    return await response.json()
}

export async function tracking3(id_telegram, items) {
    let itemsT = ''
    items.forEach((item => {
        itemsT += item.item.firma +' '+item.item.name+' '+item.item.hashrate+' '+item.count+'шт. по '+item.item.price+' USDT\n'
    }))
    let user = {
        id_telegram: id_telegram,
        items: itemsT
    };
    let response = await fetch(server_api + 'tracking3', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    });
    return await response.json()
}

export async function tracking4(id_telegram, method) {
    let pm = ['USDT TRC(20)', 'Безналичный расчет с НДС', 'На карту', 'Наличными', 'USDT (оплата с менеджером)']
    let user = {
        id_telegram: id_telegram,
        method: pm[method-1]
    };
    let response = await fetch(server_api + 'tracking4', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    });
    return await response.json()
}