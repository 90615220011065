export const text = {
    'en':{
        main:{
            text1:'Shop',
            text2:'Calculate'
        },
        shop:{
            text1:'Select manufacturer',
            text2:'ADD',
            text3:'GO TO CART',
            text4:'Coming:'
        },
        cart:{
            text1:'Shopping cart',
            text2:'Hashrate:',
            text3:'GO TO PAYMENT'
        },
        payment:{
            text1:'Order',
            text2:'Hashrate:',
            text3:'Total:',
            text4:'Payment method',
            text5:'Shipping method',
            text6:'Contact details',
            text7:'Cancel',
            text8:'Checkout',
            text9:'PAYMENT METHOD',
            text10:'USDT (pay by myself)',
            text10_1:'USDT (pay with the manager)',
            text11:'Cashless payment with VAT',
            text12:'Transfer from card to card',
            text13:'Cash',
            text14:'NEXT',
            text15:'Delivery method',
            text16:'Pickup from the office in Moscow',
            text17:'Polikarpova street, 27c3 (10:00-21:00)',
            text18:'Delivery',
            text19:'Your contact details',
            text20:'Full Name',
            text21:'Full name, last name and middle name',
            text22:'Phone',
            text23:'Shipping address',
            text24:'Address',
            text25:'ORDER COMMENT',
            text26:'Enter a comment',
            text27:'Checkout',
            text28:'Your order has been successfully placed ',
            text29:'Our manager will contact you soon',
            text30:'Return to shop',
            text31:'Your order has been successfully placed and paid',
            text32:'Count:',
            text33:'Proceed to checkout',
            text34:'After payment, you can close this window.'
        },
        calc:{
            text1:'Add device',
            text2:'Electricity cost:',
            text3:'USD/kWh',
            text4:'Select ',
            text5:' model',
            text6:'Select hashrate',
            text7:'Number of devices',
            text8:'pcs',
            text9:'Your approximate income:',
            text10:'USD/day',
            text10_1:'USD/week',
            text10_2:'USD/month',
            text11:'Day',
            text12:'Week',
            text13:'Month',
            text14:'Income:',
            text15:'Electricity cost:',
            text16:'Profit:',
            text17:'pcs',
            text18:'ORDER ASIC-MINERS',
            text19:'Apologies for the inconvenience. Unfortunately, not all the devices you have selected are currently available for purchase. We recommend choosing from the ones that are in stock. Thank you for your understanding.',
            text20:'Bitcoin price:',
            text21:'Share calculation',
            text22:'Send to telegram',
            text23:'Copied!'
        },
        prizeDay:{
            text1:'Surprise of the day',
            text2:'Get it before ',
            text3:'Spin the wheel</br>and catch an ASIC miner</br>at a super price!',
            text4:'Spin!',
            text5:'Conditions of the draw',
            text6:'Your prize:',
            text7:'Discount ',
            text8:' to ',
            text9:'Spin some more',
            text10:'add to cart',
        }
    },
    'ru':{
        main:{
            text1:'Магазин',
            text2:'Калькулятор'
        },
        shop:{
            text1:'Выберите производителя',
            text2:'ДОБАВИТЬ',
            text3:'ПЕРЕЙТИ В КОРЗИНУ',
            text4:'Прибытие:'
        },
        cart:{
            text1:'Корзина',
            text2:'Хэшрейт:',
            text3:'ПЕРЕЙТИ К ОПЛАТЕ'
        },
        payment:{
            text1:'Заказ',
            text2:'Хэшрейт:',
            text3:'Итого:',
            text4:'Способ оплаты',
            text5:'Доставка',
            text6:'Контактные данные',
            text7:'Отмена',
            text8:'Оплатить',
            text9:'СПОСОБ ОПЛАТЫ',
            text10:'USDT (оплатить самостоятельно)',
            text10_1:'USDT (оплата с менеджером)',
            text11:'Безналичный расчет с НДС',
            text12:'На карту',
            text13:'Наличными',
            text14:'ДАЛЕЕ',
            text15:'СПОСОБ ДОСТАВКИ',
            text16:'Самовывоз из офиса в Москве',
            text17:'ул. Поликарпова, 27с3 (10:00-21:00)',
            text18:'Доставка',
            text19:'Ваши данные',
            text20:'ФИО',
            text21:'Фамилия, имя и отчество',
            text22:'Телефон',
            text23:'Адрес доставки',
            text24:'Адрес',
            text25:'Комментарий к заказу',
            text26:'Введите комментарий',
            text27:'Оформить заказ',
            text28:'Спасибо за ваш заказ',
            text29:'Наш менеджер скоро свяжется с вами, для уточнения деталей',
            text30:'Назад в магазин',
            text31:'Ваш заказ успешно оформлен и оплачен',
            text32:'Количество:',
            text33:'Переход на страницу оплаты',
            text34:'Если не произошел автоматический переход, нажмите на кнопку внизу.'
        },
        calc:{
            text1:'Добавить устройство',
            text2:'Стоимость электричества:',
            text3:'Руб/кВт*ч',
            text4:'Выберите модель ',
            text5:'',
            text6:'Выберите хэшрейт',
            text7:'Введите количество',
            text8:'Шт',
            text9:'Ваш приблизительный доход:',
            text10:'Руб/день',
            text10_1:'Руб/неделя',
            text10_2:'Руб/месяц',
            text11:'День',
            text12:'Неделя',
            text13:'Месяц',
            text14:'Доход:',
            text15:'Стоимость эл-ва:',
            text16:'Прибыль:',
            text17:'шт',
            text18:'Купить ASIC-МАЙНЕРЫ',
            text19:'Извините за неудобство. К сожалению, не все выбранные вами устройства доступны для покупки в данный момент. Мы рекомендуем выбрать из тех товаров, которые есть в наличии. Благодарим за понимание.',
            text20:'Курс Bitcoin:',
            text21:'Поделиться расчетом',
            text22:'Переслать в telegram',
            text23:'Скопировано!'
        },
        prizeDay:{
            text1:'Сюрприз дня',
            text2:'Успей получить до ',
            text3:'Прокрути колесо</br>и поймай ASIC-майнер</br>по супер-цене!',
            text4:'Крутить!',
            text5:'Условия розыгрыша',
            text6:'Твой приз:',
            text7:'Скидка ',
            text8:' на ',
            text9:'Крутить еще',
            text10:'в корзину',
        }
    }
}